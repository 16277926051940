<template>
    <div v-if="searchPage" class="search-index">
        <div class="search-index__query">
            {{ $lang.pages.search.results }}<span> "{{ $route.query.q }}" </span>{{ $lang.pages.search.this }}
            {{ dateNow }}
        </div>

        <div class="container space-y-5 pb-4 lg:mx-auto lg:max-w-5xl">
            <div v-if="notResults" class="search-index__no-results">
                <h1>{{ $lang.pages.search.sorry }}</h1>
                <!--  <p>{{ $lang.pages.search.meanwhile }}</p> -->
                <img class="failed-image" :src="$assets.illustration.searchFailed" alt="" />
            </div>

            <template v-else>
                <div v-if="searchPage.data.discounts.length" class="rounded-xl border bg-white p-2">
                    <ul class="space-y-3">
                        <li v-for="(item, key) in searchPage.data.discounts" :key="key">
                            <DCardAdmin
                                v-if="item.type === 'coupon' && item.is_admin"
                                :info="item"
                                :show-brand-logo="true"
                                :store-info="item.store as any"
                            />
                            <DCardMain v-else :info="item" />
                        </li>
                    </ul>
                </div>

                <MiscStoreGallery :stores="searchPage.data.stores" />
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from '~/store/auth'
import type { Api } from '~~/global'

import { useDateFunctions } from '~/composables/DateFunctions'

const { currentDate } = useDateFunctions()

const { buildHeaders, baseURL, endpoints } = useApiConfig()
const { $lang } = useNuxtApp()
const AuthStore = useAuthStore()
const Route = useRoute()

const { month, year, day } = currentDate()

const dateNow = day + ` ${$lang.pages.search.of} ` + month + ' ' + year

const getSearch = (params: { search: string }) =>
    $fetch<Api.Responses.Pages.SearchResults>(endpoints.pages.search.path, {
        params,
        baseURL,
        headers: buildHeaders(AuthStore.SessionToken),
    })

const { data: responseData, error } = await useAsyncData('search-result-data', () =>
    getSearch({ search: Route.query.q as string }).catch((e) => e.data),
)

if (error.value || !responseData.value || responseData.value.feedback !== 'data_success') {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

if (responseData.value.feedback === 'internal_redirect' && responseData.value.data !== Route.path) {
    navigateTo(responseData.value.data, {
        redirectCode: 301,
    })
}

const searchPage = ref(responseData.value.page)

const notResults = computed(() => {
    return (
        !searchPage.value ||
        (searchPage.value.data.discounts.length === 0 && searchPage.value.data.stores.length === 0)
    )
})

const query = computed(() => {
    return Route.query
})

watch(query, async () => {
    try {
        const result = await getSearch({ search: query.value.q as string })
        if (result.feedback === 'data_success') {
            searchPage.value = result.page
        }
    } catch (e) {}
})

const {
    public: { origin },
} = useRuntimeConfig()

const title = computed(() => `${$lang.pages.search.results_for} ` + Route.query.q + '"')

useSeoMeta({
    title: title.value,
    ogUrl: origin + Route.path,
    ogTitle: title.value,
    ogImageAlt: title.value,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>

<style lang="postcss" scoped>
.search-index {
    @apply space-y-5;
    &__query {
        @apply bg-site-primary bg-opacity-10 p-5 text-center font-semibold text-gray-800 md:text-xl;
        span {
            @apply whitespace-pre text-site-primary;
        }
    }
    &__no-results {
        @apply space-y-2 text-center;
        h1 {
            @apply text-2xl font-semibold text-gray-800;
        }
        p {
            @apply text-gray-800;
        }
        img {
            @apply mx-auto h-52 md:h-60 lg:h-96;
        }
    }
    &__tabs-section {
        @apply mt-3 space-y-2 text-center;
        h1 {
            @apply text-2xl font-semibold text-gray-800;
        }
        p {
            @apply flex justify-center gap-1 text-gray-800;
            img {
                @apply h-5;
            }
        }
        .failed-image {
            @apply mx-auto w-2/3 md:w-1/3;
        }
    }
    &__tabs-list {
        @apply mx-auto flex max-w-max justify-center space-x-1 rounded-xl border bg-white p-1 lg:space-x-2 lg:p-2;
        button {
            @apply flex w-40 items-center justify-center space-x-2 rounded-lg border border-gray-200 p-2 hover:cursor-pointer;
            img {
                @apply w-4;
            }
            &.active {
                @apply bg-site-primary text-white;
            }
        }
    }
    &__content-section {
        @apply mb-10 space-y-3;
        h2 {
            @apply text-xl font-medium text-gray-800;
            span {
                @apply font-semibold;
            }
        }
    }
}
</style>
